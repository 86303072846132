@mixin overlay($bg:  $white, $alpha: 0.5) {
    background-color: rgba($bg, $alpha);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .section-title {
    margin-bottom: 30px;
    text-align: center;
    
  
    h2 {
      font-weight: 500;
      color:$orange;
    }
  }
.galerie-projekte{
  min-height: 95vh;
}
.galerie-item {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    text-align: center;
    transition: 0.4s;
    margin-top: 20px;
  
    .image {
      position: relative;
      overflow: hidden;
      display: block;
      max-height: 170px;
  
      &:before {
        @include overlay(#292929, 0.8);
        opacity: 0;
        transition: 0.4s;
      }
  
      i {
        border: 2px solid #ffffff;
        border-radius: 50%;
        color: #fff;
        font-size: 20px;
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease 0s;
        line-height: 46px;
        height: 50px;
        width: 50px;
        z-index: 9;
  
        &:hover {
          background-color: $backDarker;
          color: $orange;
        }
      }
    }
  
    .title {
      margin-top: 0;
      padding: 20px 10px;
      font-weight: 500;
      background-color:$textBack;
  
      a {
        color: $orange;
        font-size: 16px;
        text-transform: capitalize;
      }
    }
  
    &:hover:not(.coming) {
      transform: translateY(-10px);
  
      .image {
        &:before, i {
          opacity: 1;
        }
      }
    }
  
    &.coming {
      pointer-events: none;
    }
  }
.rr_slope{
 // position: absolute;
    left: -20px;
    width: 106%;
    height: 10vw;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    //background-color: white;
    z-index: 5;
}
.rr_slopestart{
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  transform: rotate(-3deg);
  margin-left: -3%;
 top:0;
}
.rr_slopeend {
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
  bottom: 0;
  margin-left: -3%;
}
.rr_platzhalter{
    height:30px;
    width:100%;
    background-color: white;
}

.rr_introText, .rr_galleryText{
    text-align: center;
    padding-top: 5vw;
    padding-bottom: 50px;

}

.rr_galleryText{
  padding-top: 1vw;
}


.rr_introText>h2, .rr_galleryText>h2{
    color:$orange;
    font-size: 36px;
    font-weight: 500;
    line-height: 36px;
    padding-bottom: 20px;
}

.rr_introText>p, .rr_galleryText>p{
    text-align: left;
    font-size: 18px;
    line-height: 28px;
}


.portfolio-details-content ul.project-info li a::after {

    content: ", ";
    white-space: pre;

}

video{width:100%;}

.service-item>p{
    color:rgb(225, 225, 225);
}
.service-item>h3{
    color:white;
}
.section-title{
    margin-top: 30px;
}

.section-title>.title{
    
    color:white;
}
.section-title>.title.black{

    color:$orange;
}

.section-title>.sub-title{
    color:white;
}
.section-title>.sub-title.black{
    color:$orange;
}
.main-slider-1{
    //height: 100%;
}
.main-slider-content{
    width: 100%;
    height: 100%;
    padding-right: 7vw;
    padding-left: 7vw;
    margin-right: auto;
    margin-left: auto;
}

.main-slider-content>h1{
    //font-size: 60px;
    
    color:rgba(255, 120, 0, 1);
}
.main-slider-content>h2{
    color:rgba(255, 120, 0, 1);
    //font-size: 40px;
}

.sticky{
    background-color:$backDarker;
    transition: background-color 0.5s ease-in-out;
}

.project-header{
    position: fixed;
    background-color: $white;
}

.footer-section {
   // background-color: $white;
    color:$textLight;
}

.footer-sectionEnd {
    background-color: $backDarker;
}



.backImage{
    object-fit: cover;
    position: relative;

}

.carousel-indicators,.carousel-control-prev, .carousel-control-next{
    display:none;
}

.carousel,.carousel-item,.carousel-inner,.backImage {
    width: 100%;
    height: 100%;
}

.carousel-inner {
    > .carousel-item {
     opacity: 0;
     top: 0;
     left: 0;
     width: 100%;
     display: block;
     position: absolute;
     z-index:0;
     transition: none;
     transform: translate3d(0,0,0) !important;
     
     transition: opacity 0.6s ease-in-out;
     &:first-of-type {
       position:relative;
     } 
     }
    > .active {
     opacity: 1;
     z-index:3;
     
   }
   
    > .next.left,
    > .prev.right {
     transition: opacity 0.6s ease-in-out;
     opacity: 1;
     left: 0;
     z-index:2;
     }                                                                                                             
    > .active.left,
    > .active.right {
     z-index:1;
     }
   }
   .carousel-control {
   z-index:4;
   }


.bg-projekte {
    background-color: $grey !important;
    padding-top:10vw;
    padding-bottom:5vw;
    min-height: 93vh;
  }

  .main-menu > ul > li > a{
    color:rgb(255, 158, 73);
  }

  .main-menu > ul > li > a:link{
    color:rgb(255, 158, 73);
  }

  .main-menu > ul > li > a.active {
    color:rgba(255, 120, 0, 1);
  }

  .main-menu > ul > li > a:hover {
    color:rgba(255, 120, 0, 1);
  }



@media only screen and (max-width: 767px){
    .header-section, .project-header {
        position: fixed;
    }

    .bg-projekte {
        padding-top:15vw;
        
      }
      .footer-copyright p {
        text-align: center;
        font-size: 3vw;
    }
    }

@media only screen and (max-width: 575px), only screen and (max-width: 767px)
{
    .header-logo a img {
        max-width: 9vw;
        max-height: 9vw;
        margin-top:10px;
        margin-bottom:10px;
      
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px)
{
.header-section, .project-header {
    position: fixed;
}

.header-logo a img {
    max-width: 9vw;
    max-height: 9vw;
    margin-top:10px;
    margin-bottom:10px;
  
}
}

@media only screen and (max-width: 991px) 
{
    .container{
        margin-top:5vw;
    }
}


@media (min-width: 992px){
    .bg-projekte {
        padding-top:200px;
        
      }
}